import { Pagination as MuiPagination, PaginationProps as MuiPaginationProps, PaginationRenderItemParams } from '@mui/material';

type PaginationBaseProps = Pick<
	MuiPaginationProps,
	'shape' | 'count' | 'variant' | 'color' | 'showFirstButton' | 'showLastButton' | 'onChange' | 'page'
> & {
	renderItem?: (item: PaginationRenderItemParams) => React.ReactNode;
};

export interface PaginationProps extends PaginationBaseProps {}

const Pagination = ({ color, shape, count, variant, ...rest }: PaginationProps) => (
	<MuiPagination color={color} count={count} variant={variant} shape={shape} {...rest} />
);

Pagination.defaultProps = {
	color: 'primary',
	variant: 'outlined',
	shape: 'rounded',
	count: 1,
	showLastButton: true,
	showFirstButton: true,
};

export default Pagination;
