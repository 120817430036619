export const PERMISSIONS = {
	view_orders: { slug: 'view_orders', permissionType: 'Sales' },
	edit_orders: { slug: 'edit_orders', permissionType: 'Sales' },
	create_orders: { slug: 'create_orders', permissionType: 'Sales' },
	cancel_orders: { slug: 'cancel_orders', permissionType: 'Sales' },
	view_buyers: { slug: 'view_buyers', permissionType: 'Buyer' },
	edit_buyers: { slug: 'edit_buyers', permissionType: 'Buyer' },
	create_buyers: { slug: 'create_buyers', permissionType: 'Buyer' },
	delete_buyers: { slug: 'delete_buyers', permissionType: 'Buyer' },
	view_buyer_group: { slug: 'view_buyer_group', permissionType: 'Buyer Group' },
	edit_buyer_group: { slug: 'edit_buyer_group', permissionType: 'Buyer Group' },
	create_buyer_group: { slug: 'create_buyer_group', permissionType: 'Buyer Group' },
	create_user: { slug: 'create_user', permissionType: 'User' },
	view_user: { slug: 'view_user', permissionType: 'User' },
	edit_user: { slug: 'edit_user', permissionType: 'User' },
	delete_user: { slug: 'delete_user', permissionType: 'User' },
	create_role: { slug: 'create_role', permissionType: 'Roles' },
	view_role: { slug: 'view_role', permissionType: 'Roles' },
	edit_role: { slug: 'edit_role', permissionType: 'Roles' },
	delete_role: { slug: 'delete_role', permissionType: 'Roles' },
	create_labels: { slug: 'create_labels', permissionType: 'Label' },
	import_export: { slug: 'import_export', permissionType: 'Import Export' },
	org_settings: { slug: 'org_settings', permissionType: 'Org Settings' },
	view_dashboard: { slug: 'view_dashboard', permissionType: 'Dashboard' },
	view_reports: { slug: 'view_reports', permissionType: 'Reports' },
	create_files_dam: { slug: 'create_files_dam', permissionType: 'Files' },
	edit_files_dam: { slug: 'edit_files_dam', permissionType: 'Files' },
	view_files_dam: { slug: 'view_files_dam', permissionType: 'Files' },
	delete_files_dam: { slug: 'delete_files_dam', permissionType: 'Files' },
	access_control_dam: { slug: 'access_control_dam', permissionType: 'Files' },
	download_file_dam: { slug: 'download_file_dam', permissionType: 'Files' },
	view_catalog: { slug: 'view_catalog', permissionType: 'Catalog' },
	create_catalog: { slug: 'create_catalog', permissionType: 'Catalog' },
	edit_catalog: { slug: 'edit_catalog', permissionType: 'Catalog' },
	delete_catalog: { slug: 'delete_catalog', permissionType: 'Catalog' },
	mail_setting: { slug: 'set_sender_email', permissionType: 'Mail' },
	view_payment: { slug: 'view_payment_method', permissionType: 'Saved cards' },
	add_payment: { slug: 'add_payment_method', permissionType: 'Saved cards' },
	edit_payment: { slug: 'edit_payment_method', permissionType: 'Saved cards' },
	delete_payment: { slug: 'delete_payment_method', permissionType: 'Saved cards' },
	collect_payment_card: { slug: 'collect_payment_card', permissionType: 'Payment mode' },
	collect_payment_link: { slug: 'collect_payment_link', permissionType: 'Payment mode' },
	collect_payment_offline: { slug: 'collect_payment_offline', permissionType: 'Payment mode' },
	collect_payment_credits: { slug: 'collect_payment_credits', permissionType: 'Payment mode' },
	direct_payment: { slug: 'direct_payment', permissionType: 'Payment mode' },
	credit_top_up: { slug: 'credit_top_up', permissionType: 'Credits' },
	collect_payment_pos: { slug: 'collect_payment_pos', permissionType: 'Payment mode' },
	collect_payment_for_order: { slug: 'collect_payment_for_order', permissionType: 'Payment mode' },
	refund_source: { slug: 'refund_source', permissionType: 'Refund Mode' },
	refund_credits: { slug: 'refund_credits', permissionType: 'Refund Mode' },
	wallet_view: { slug: 'wallet_view', permissionType: 'Credits' },
	wizpay_dashboard_full_access: { slug: 'wizpay_dashboard_full_access', permissionType: 'Payment dashboard' },
	view_payment_history: { slug: 'view_payment_history', permissionType: 'Payment dashboard' },
	view_credit_history: { slug: 'view_credit_history', permissionType: 'Credits' },
	view_invoice: { slug: 'view_invoice', permissionType: 'Invoice' },
	view_inventory: { slug: 'view_inventory', permissionType: 'Inventory' },
	edit_confirmed_orders: { slug: 'edit_confirmed_orders', permissionType: 'Sales' },
	create_wizshop_user: { slug: 'create_wizshop_user', permissionType: 'Wizshop' },
	view_wizshop_user: { slug: 'view_wizshop_user', permissionType: 'Wizshop' },
	edit_wizshop_user: { slug: 'edit_wizshop_user', permissionType: 'Wizshop' },
	delete_wizshop_user: { slug: 'delete_wizshop_user', permissionType: 'Wizshop' },
	view_insights: { slug: 'view_insights', permissionType: 'insights' },
	create_authorization: { slug: 'create_authorization', permissionType: 'Authorization' },
	void_authorization: { slug: 'void_authorization', permissionType: 'Authorization' },
	cart_price_override: { slug: 'cart_price_override', permissionType: 'Sales' },
	invite_wizshop_user: { slug: 'invite_wizshop_user', permissionType: 'Wizshop' },
	delete_product: { slug: 'delete_product', permissionType: 'Product' },
	edit_fulfilment_status: { slug: 'edit_fulfilment_status', permissionType: 'Sales' },
	edit_order_tag: { slug: 'edit_order_tag', permissionType: 'Sales' },
};
