import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import apply_validations, { ValidationProps } from 'src/utils/apply_validations';
import dayjs from 'dayjs';
import { colors } from 'src/utils/theme';

interface Props {
	name: string;
	label?: string;
	validations?: ValidationProps;
	refInput?: any;
	value?: any;
	defaultValue?: any;
	onChangeCapture?: any;
}

const DateEditField = ({ name, label, validations, defaultValue, onChangeCapture, ...props }: Props) => {
	const [new_value, set_new_value] = useState(defaultValue);
	const { control, watch, setValue } = useFormContext();

	const watched_value = watch(name);

	useEffect(() => {
		if (!defaultValue) setValue(name, ''); // update the form value on mount, as it's setting up today's date if defaultValue is falsy
	}, []);

	useEffect(() => {
		if (watched_value === new_value) return;
		set_new_value(watched_value);
	}, [watched_value]);

	const handle_change = (onChange: any, val: any) => {
		setValue(name, val);
		let date = dayjs(val).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
		if (date === 'Invalid Date') {
			onChange(null);
		} else {
			onChange(date);
		}
		let parsed_date: string | null = dayjs(val).format('MM/DD/YYYY');
		if (parsed_date === 'Invalid Date') {
			parsed_date = null;
		}
		if (onChangeCapture) onChangeCapture({ target: { name, value: parsed_date } });
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={apply_validations({ label, name, ...validations })}
				render={({ field, fieldState: { error } }) => {
					return (
						<DatePicker
							sx={{ width: '100%' }}
							{...field}
							{...props}
							format='MM/DD/YYYY'
							inputFormat='MM-DD-YYYY'
							label={`${label}${validations?.required ? '*' : ''}`}
							value={field?.value ? dayjs(field?.value) : null}
							onChange={(val) => handle_change(field.onChange, val)}
							renderInput={(params: any) => <TextField {...params} label={label} />}
							slotProps={{
								day: { sx: { fontSize: '14px' } },
								calendarHeader: { sx: { fontSize: '14px' } },
								textField: {
									helperText: error ? <p style={{ color: colors.red }}>{error?.message}</p> : '',
								},
							}}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
};

export default DateEditField;
